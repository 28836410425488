<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        Smart technologies in the African medical sector - Increasing the
        adoption rate.
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1636104420/medical-doctor-girl-working-with-microscope-young-female-scientist-doing-vaccine-research_sgorph.jpg"
            alt=""
            width="100%"
            style="max-height: 32rem;width: 100%;object-fit: contain"
          />
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            Smart technologies in the African medical sector - Increasing the
            adoption rate.
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: Nov 04, 2021</p>
            </span>
          </div>
          <p class="">
            The African medical sector is one of the most under equipped in the
            world. Many African countries lack proper health care facilities and
            continue to use poor medical equipment to treat sick and dying
            people. Pregnant women and children are the primary recipients of
            this underdevelopment in the state of medical facilities in Africa.
          </p>
          <p>
            According to WHO, every day in 2017, approximately 810 women died
            from preventable causes related to pregnancy and childbirth. These
            deaths undeniably caused great pain to the families of these women.
            This pain is more felt when, sooner or later, people affected by the
            deaths of these women find out that with the right medical equipment
            and technology, the death would have been avoided.
          </p>
          <p>
            Women and children are not the only populations affected. The gap in
            the health care system in Nigeria is felt by people of all genders,
            ages and tribes. With better equipped medical facilities, the
            mortality rate of people in Africa who die from preventable causes
            can be reduced
          </p>
          <p>
            People in today's Africa die from preventable or treatable causes,
            which is a terrible tragedy. The lack of proper health
            infrastructure in Africa is affecting people's well-being and
            endangering the lives of the most vulnerable members of society,
            such as pregnant women, the elderly, and children.
          </p>
          <p>
            Although deterioration in infrastructure is the most common problem
            blamed for the constant state of decline in the Nigerian health
            sector, there are many others. One of the chief culprits that has
            adversely plagued healthcare delivery in Africa is the lack of smart
            technologies at medical facilities.
          </p>
          <h3 class="mb-3">
            Benefits of Smart Technologies in the African Medical Sector
          </h3>

          <p class="mb-5">
            The healthcare sector is not what it was just five years ago in
            continents with higher exposure and adoption rate than Africa. This
            is largely due to technological advancements and the enormous number
            of creative digital solutions that are presented on a daily basis.
            Many technical solutions to various difficulties in the world of
            medicine have been presented, and these have significantly changed
            and enhanced the sector.
          </p>
          <p class="mb-5">
            Many advancements in data gathering, therapies, research, and
            medical gadgets such as cold chain equipment and data recorders have
            had a significant impact on medicine.
          </p>
          <p>
            Here are a few benefits of Smart Technologies in the African Medical
            Sector. We will also detail why we think health facilities in Africa
            should pursue the adoption of these technologies.
          </p>

          <h3 class="">
            Enormous Data
          </h3>

          <figure class="pt-8 pb-4">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1636103999/images_5_buyvvf.jpg"
              alt=""
              class="pic-size"
            />
          </figure>

          <p>
            Data is a huge deal right now. In the medical and healthcare
            industry, data is everything. There is a huge amount of data
            available that when analyzed, can offer significant insights into
            the state of the healthcare industry as a whole. For example, by
            getting the data of stored medicine or vaccines, health care
            professionals can help predict, account for, and control the wastage
            of these medications.
          </p>

          <p>
            When transporting vaccines for mass vaccination and immunization, it
            is important to keep accurate data of the vaccines and it's storage
            conditions at intervals to ensure it stays potent and prevents
            wastage. Just last month, the federal government of Nigeria employed
            the use of MOTE, a portable data logger to check temperature
            excursions as well as record the storage and location data of the
            vaccines.
          </p>
          <p>
            We recognize this as a great move by Nigeria to adopt smart
            technologies to improve the wellbeing of its citizens. Especially
            with the wake of the COVID-19 vaccine.
          </p>

          <h3 class="">
            Improved Care and Efficiency
          </h3>

          <figure class="pt-3 pb-4">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1636104454/afro-doctors-consulting-young-adult-hospital-ward_r8iimz.jpg"
              alt=""
              width="100%"
              class=""
            />
          </figure>
          <p>
            In modern hospitals, doctors and nurses now employ the use of mobile
            devices to record real time data from patients. By using these
            mobile devices to record data, health personnel can instantly update
            the medical histories of these patients.
          </p>
          <p>
            The benefits of this move in the medical sector is huge. First this
            new method of keeping patient data will result in more accurate and
            efficient diagnosis and treatment. Prioritizing key patient data and
            lab findings has significantly improved healthcare quality.
          </p>
          <p>
            Thanks to smart technology, there is excellent and easily accessible
            treatment for a wide range of ailments, better care for the sick and
            also proper storage of medicine like vaccines and eye drops.
          </p>
          <h3 class="mt-5">
            Medical Experimentation
          </h3>

          <figure class="pt-3 pb-4">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1636103997/20211104_130356_x0tktx.jpg"
              alt=""
              class="pic-size"
            />
          </figure>
          <p>
            Another big benefit of smart technologies is improving how medical
            experiments are conducted. Experiments now take months or weeks
            rather than years. This is because, instead of relying solely on
            human volunteers, it is now possible to imitate human reactions to a
            specific medicine.
          </p>
          <p>
            Technological advancements such as this one have greatly helped
            Africa conquer pandemics. If fully incorporated into the African
            system, we can expect faster and more effective solutions.
          </p>
          <h3 class="mt-5">
            Last-Mile Distribution of COVID 19 Vaccines
          </h3>

          <figure class="pt-3 pb-4">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1636103991/images_4_jzhjf4.jpg"
              alt=""
              class="pic-size"
            />
          </figure>
          <p>
            Secure technologies are being sought by government agencies and
            healthcare institutions to scale the numerous hurdles of COVID-19
            vaccine rollout.
          </p>
          <p>
            One of the major challenges faced by the government in tackling this
            issue is the problem of visibility. During transportation of
            vaccines, it poses quite difficult for the vaccines' storage
            conditions to be properly monitored and recorded. Most of the time,
            the health organization records a lot of vaccine losses and this
            adversely affects the medical welfare of those needing the vaccines.
          </p>
          <p>
            With a lack of adequate cold chain system for these vaccines, they
            can lose their potency and become useless when administered to
            people needing them. Thankfully, the adoption rate of smart
            technologies to solve this problem is increasing in Nigeria and some
            other African countries.
          </p>

          <h3 class="mt-5">
            Increasing the Adoption Rate of Smart Technologies is important -
            Why?
          </h3>

          <figure class="pt-3 pb-4">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1636104008/portrait-african-american-woman-working-as-doctor_482257-10153_wfkog5.jpg"
              alt=""
              class="pic-size"
            />
          </figure>
          <p>
            We believe no reason could surpass what we call the ultimate reason
            - TO SAVE LIVES!
          </p>
          <p>
            By adopting these smart technologies, African countries are on their
            way to not just improving their medical sector, but are also going
            to save millions of lives in Africa. Thankfully, new startups are
            arising in Africa to change the story and make a difference in the
            African medical sector. In a publication by CNN, a few high-flying
            African organizations were listed who contributed immensely to the
            fight against COVID-19; you can read the publication here.
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{ hover }">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
// import img2 from '../../assets/images/Rectangle 26.png';
// import img3 from '../../assets/images/image 11 (1).png';
import image4 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
// import image5 from "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg";
import image6 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    loaded: false,
    loading: true,
    info: ["News", "Health", "Logistics"],
    rightSide: [
      {
        img: image4,
        content:
          "INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About Glaucoma And Its Impact On The Health Of Nigerians",
        date: "July 19, 2021",
        link:
          "/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          " Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "June 13, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image6,
        content:
          " Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
        date: "June 13, 2021",
        link:
          "/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          "INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About Glaucoma And Its Impact On The Health Of Nigerians",
        date: "July 19, 2021",
        link:
          "/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          " Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "June 13, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image6,
        content:
          " Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
        date: "June 13, 2021",
        link:
          "/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
      },
    ],
    mySVG: require("../../assets/Logo/search.svg"),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://gricd.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Blog",
                item: "https://gricd.com/blog",
              },
              {
                "@type": "ListItem",
                position: 3,
                name:
                  "Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
                item:
                  "https://gricd.com/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
              },
            ],
          },
        },
      ],
    };
  },
  created() {
    const readyHandler = () => {
      //   console.log(document.readyState);
      if (document.readyState == "complete") {
        this.loading = false;
        this.loaded = true;
        document.removeEventListener("readystatechange", readyHandler);
      }
    };

    document.addEventListener("readystatechange", readyHandler);

    readyHandler(); // in case the component has been instantiated lately after loading
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        // this.loading = false;
        // this.loaded = true;
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black !important;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
// p span {
//   text-decoration: underline;
//   color: #28a84a;
// }
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
.grey-text {
  color: #676464;
}
h3 {
  font-weight: 400;
}
.pic-size {
  width: 19rem;
  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
